import { render, staticRenderFns } from "./PaginaZuccheri.vue?vue&type=template&id=4c94d9b0&"
import script from "./PaginaZuccheri.vue?vue&type=script&lang=js&"
export * from "./PaginaZuccheri.vue?vue&type=script&lang=js&"
import style0 from "@/assets/paginaZuccheri/paginaZuccheri.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports